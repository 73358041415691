import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import App from './App.vue'
import { gotoAnchor } from '@/util/anchor'
import createRouter from './routers'
import './util/gt' // 极验验证js
import request from '../fetch/request'
// 引入 element-ui 组件（按需引入: 需要的组件在文件element-ui.js中开启）
import './element-ui.js'

Vue.use(VueRouter)
// Vue.use(Form)
// Vue.use(FormItem)
// Vue.use(Input)
// Vue.use(Dropdown)
// Vue.use(DropdownMenu)
// Vue.use(DropdownItem)

Vue.config.productionTip = false
// Vue.prototype.$message = Message

// 全局挂载Ajax请求方法
Vue.prototype.$request = request
Vue.prototype.$gotoAnchor = gotoAnchor

new Vue({
  router: createRouter(),
  store,
  render: (h) => h(App),
}).$mount('#app')
