import VueRouter from 'vue-router'
import routes from './routes'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default function createRouter() {
  return new VueRouter({
    routes,
    mode: 'history',
    base: process.env.BASE_URL,
    fallback: false,
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return {
          selector: to.hash,
        }
      }

      if (savedPosition) {
        return savedPosition
      }

      return { x: 0, y: 0 }
    },
  })
}
