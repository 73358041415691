import store from '@/store'
const scrollElementIntoView = (element, behavior) => {
  // fix safari API 问题
  let scrollTop = window.pageYOffset || element.scrollTop
  const finalOffset = element.getBoundingClientRect().top + scrollTop

  window.parent.scrollTo({
    top: finalOffset,
    behavior: behavior || 'auto',
  })
}

function gotoAnchor(elementId) {
  if (elementId === 'contact-us') {
    store.commit('UPDATE_SHOW_CONTACT_DIALOG', !store.state.showContactDialog)
    return false
  }
  this.$nextTick(() => {
    const element = document.getElementById(elementId)
    if (element) {
      scrollElementIntoView(element, 'smooth')
    }
  })
}

export { gotoAnchor }
