<template>
  <el-dialog
    title=""
    :visible.sync="contactDialogVisible"
    width="1050px"
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="contact-wrapper" id="contact-us">
      <div class="contact-main">
        <el-form label-position="right" label-width="0px" :rules="rules" :model="contactInfo" ref="contactInfoFrom">
          <el-row>
            <el-col :span="12" class="pdr50">
              <div class="lable-text"><i>*</i>单位名称：</div>
              <el-form-item label="" prop="corporateName">
                <el-input
                  v-model="contactInfo.corporateName"
                  autocomplete="off"
                  placeholder="请填写"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="pdl50">
              <div class="lable-text"><i>*</i>联系人：</div>
              <el-form-item label="" prop="name" required>
                <el-input v-model="contactInfo.name" autocomplete="off" placeholder="请填写" maxlength="20"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12" class="pdr50">
              <div class="lable-text"><i>*</i>手机号：</div>
              <el-form-item label="" prop="mobile" required>
                <el-input
                  v-model="contactInfo.mobile"
                  autocomplete="off"
                  placeholder="请填写"
                  maxlength="11"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="pdl50">
              <div class="lable-text">邮箱：</div>
              <el-form-item label="" prop="mail">
                <el-input v-model="contactInfo.mail" autocomplete="off" placeholder="请填写"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <div class="lable-text">使用场景：</div>
              <el-form-item label="" prop="applicationNotes">
                <el-input
                  type="textarea"
                  v-model="contactInfo.applicationNotes"
                  autocomplete="off"
                  placeholder="留言内容"
                  :autosize="{ minRows: 5, maxRows: 5 }"
                  maxlength="1024"
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item class="btns">
                <div class="commit-btn" @click="submitForm">提交</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
/* 联系我们 */
import { mapState } from 'vuex'
import { emailReg } from '@/util/regexp'
export default {
  name: 'ContactUs',
  data() {
    const checkDecLen = (rule, value, callback) => {
      if (value && value.length < 5) {
        return callback(new Error('请输入至少五个字符'))
      } else {
        callback()
      }
    }
    const checkMail = (rule, value, callback) => {
      if (value) {
        if (emailReg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确邮箱'))
        }
      } else {
        callback()
      }
    }
    return {
      rules: {
        corporateName: [{ required: true, message: '请填写单位全称', trigger: ['blur', 'change'] }],
        name: [
          { required: true, message: '请填写联系人', trigger: ['blur', 'change'] },
          { pattern: /^([\u4E00-\u9FA5|a-zA-Z])+$/, message: '请填写中文或者英文', trigger: ['blur', 'change'] },
        ],
        mobile: [
          { required: true, message: '请填写手机号', trigger: ['blur', 'change'] },
          { pattern: /^[1]([3-9])[0-9]{9}$/, message: '请填写正确的手机号', trigger: ['blur'] },
        ],
        mail: [{ validator: checkMail, trigger: ['blur'] }],
        applicationNotes: [{ validator: checkDecLen, trigger: ['blur'] }],
      }, // 表单验证规则
      contactInfo: {
        mail: '',
        name: '', // 联系人
        mobile: '', // 手机号码
        corporateName: '', // 公司名称
        applicationNotes: '', // 申请说明
        page: 1, // 提交页面
        source: '官网', // 申请途径
      }, // 表单信息
      captchaObj: null, // 极验回调数据
    }
  },
  computed: {
    ...mapState(['showContactDialog']),
    contactDialogVisible: {
      get() {
        return this.showContactDialog
      },
      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.initGeetest() // 初始化极验验证
  },
  methods: {
    // 初始化极验验证
    async initGeetest() {
      let that = this
      const data = await this.$request.get('geetest/firstRegister')
      window.initGeetest(
        {
          gt: data.gt,
          challenge: data.challenge,
          offline: !data.success,
          new_captcha: data.newCaptcha,
          product: 'bind',
        },
        function (captchaObj) {
          captchaObj
            .onReady(function () {
              that.captchaObj = captchaObj
            })
            .onSuccess(function () {
              let result = captchaObj.getValidate()
              if (!result) {
                that.$message.error('请完成验证!')
              }
              const params = that.contactInfo
              const height = document.documentElement.clientHeight / 2 - 24
              that.$request
                .post('mail/sendMail', { params })
                .then(() => {
                  that.$message({
                    message: '提交成功，我们会尽快联系您!',
                    type: 'success',
                    offset: height,
                  })
                  captchaObj.reset()
                  that.closeDialog()
                })
                .catch((err) => {
                  captchaObj.reset()
                  that.$message({
                    message: '提交失败!',
                    type: 'error',
                    offset: height,
                  })
                })
            })
            .onError(function () {
              captchaObj.reset()
              console.log('校验失败')
            })
        }
      )
    },

    // 关闭弹窗
    closeDialog() {
      this.resetForm()
      this.$store.commit('UPDATE_SHOW_CONTACT_DIALOG', false)
    },

    // 清除表单
    resetForm() {
      this.$refs.contactInfoFrom.resetFields()
    },

    // 提交表单
    submitForm() {
      const that = this
      this.$refs.contactInfoFrom.validate((valid) => {
        if (valid) {
          if (that.captchaObj) {
            that.captchaObj.verify()
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-wrapper {
  width: 100%;
  padding: 30px 75px 8px;
  background: #fff;
  font-family: PingFangSC-Regular, PingFang SC;

  .contact-title {
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    padding: 0 0 48px;
    text-align: center;
    font-family: PingFangSC-Medium, PingFang SC;
  }

  .contact-main {
    .lable-text {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      padding-bottom: 14px;

      i {
        color: #cc3a16;
        font-style: normal;
        padding: 0 8px 0 0;
      }
    }

    .btns {
      display: flex;
      justify-content: center;
      margin-top: 18px;
      .commit-btn {
        width: 400px;
        height: 48px;
        line-height: 48px;
        background: #19c2a9;
        border-radius: 2px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
      }
    }

    .pdr50 {
      padding-right: 50px;
      padding-bottom: 10px;
    }

    .pdl50 {
      padding-left: 50px;
      padding-bottom: 10px;
    }
  }
}
</style>
