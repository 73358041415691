<template>
  <div id="app">
    <template>
      <ContactUsDialog />
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import ContactUsDialog from '@/components/ContactUsDialog.vue'
export default {
  name: 'App',
  components: {
    ContactUsDialog,
  },
}
</script>

<style lang="scss">
@import './assets/styles/index';

#app {
  min-width: 1200px;
}

// 滚动条样式
html {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 4px;
  }
}

.el-popup-parent--hidden {
  padding-right: 8px !important;

  .page-header {
    right: 4px;
  }
}
</style>
