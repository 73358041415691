import axios from 'axios'
import { baseUrl } from './env'

const instance = axios.create({
  baseURL: `${baseUrl}/wisdomorcharddevice/api/v1`,
  timeout: 20000, // 设置20s超时
})

// 设置请求拦截器
instance.interceptors.request.use(
  (config) => {
    const method = config.method
    let type = 'data'
    if (method === 'get' || method === 'delete' || method === 'head' || method === 'options') {
      type = 'params'
    } else {
      config[type] = config[type] && config[type]['params']
    }

    if (!config[type]) {
      config[type] = {}
    }

    return config
  },
  () => {
    return Promise.reject({
      message: '当前网络不佳，请稍后再试-1',
    })
  }
)

// 设置响应拦截器
instance.interceptors.response.use(
  (res) => {
    console.log('res-success: ', res)
    const { status, data, message, code } = res.data || {}
    if (!status) {
      return Promise.reject({
        data,
        message: message || '当前网络不佳，请稍后再试-2',
      })
    } else if (code !== 200) {
      return Promise.reject({
        data,
        message: message || '当前网络不佳，请稍后再试-3',
      })
    }

    return data
  },
  (err) => {
    // 处理接口请求超时
    if (err.message === 'timeout of 20000ms exceeded') {
      // this.$message.warning('接口请求失败: 超过20秒无响应')
      console.log('接口请求失败: 超过20秒无响应')
      return Promise.reject({
        message: err.message,
      })
    }

    if (err.response) {
      console.log('res-err: ', err.response)
      // this.$message.error(err.response.data.message || '当前网络不佳，请稍后再试-4')
      console.log(err.response.data.message || '当前网络不佳，请稍后再试-4')
      return Promise.reject(err.response.data)
    }
  }
)

export default instance
