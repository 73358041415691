const Index = () => import(/* webpackChunkName: "index" */ '../views/index/Index')
const FarmersCloud = () => import(/* webpackChunkName: "farmersCloud" */ '../views/farmersCloud/Index')
const FarmersTreasure = () => import(/* webpackChunkName: "farmersTreasure" */ '../views/farmersTreasure/Index')
const AgriculturalFinancialService = () =>
  import(/* webpackChunkName: "agriculturalFinancialService" */ '../views/agriculturalFinancialService/Index')
const AgriculturalDigitalBrain = () =>
  import(/* webpackChunkName: "agriculturalDigitalBrain" */ '../views/agriculturalDigitalBrain/Index')
const LingjianOption = () => import(/* webpackChunkName: "lingjianOption" */ '../views/lingjianOption/Index')
const LingjianYun = () => import(/* webpackChunkName: "lingjianYun" */ '../views/lingjianYun/Index')
const AboutUs = () => import(/* webpackChunkName: "aboutUs" */ '../views/aboutUs/Index')
const NotFound = () => import(/* webpackChunkName: "notFound" */ '../NotFound')

export default [
  {
    path: '/',
    component: Index,
  },
  {
    path: '/farmersCloud',
    component: FarmersCloud,
    meta: {
      line: true,
    },
  },
  {
    path: '/farmersTreasure',
    component: FarmersTreasure,
    meta: {
      line: true,
    },
  },
  {
    path: '/agriculturalFinancialService',
    component: AgriculturalFinancialService,
    meta: {
      line: true,
    },
  },
  {
    path: '/agriculturalDigitalBrain',
    component: AgriculturalDigitalBrain,
    meta: {
      line: true,
    },
  },
  {
    path: '/lingjianOption',
    component: LingjianOption,
    meta: {
      line: false,
    },
  },
  {
    path: '/lingjianYun',
    component: LingjianYun,
    meta: {
      line: true,
    },
  },
  {
    path: '/aboutUs',
    component: AboutUs,
    meta: {
      line: false,
    },
  },
  {
    path: '*',
    component: NotFound,
  },
]
